<script>
import interact from 'interactjs';
import { sceneEditor } from './js/scene-editor';
import BootstrapMenu from 'bootstrap-menu';

function dragMoveListener (event) {
  var target = event.target
  var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
  var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy
  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'
  target.setAttribute('data-x', x)
  target.setAttribute('data-y', y)
}
window.dragMoveListener = dragMoveListener;

export default {  
  data() {
    return {
      videoMixer: null,
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    },
  },
  components: {
  },
  methods: {
    modalShown() {
      this.resizeCanvas();
      this.createObjects();
      this.createSceneMenu();
    },
    modalHidden() {
      this.clearAllObjects();
    },
    setVideoMixer(videoMixer) {
        this.videoMixer = videoMixer;
    },
    resizeCanvas() {
      var canvas = document.getElementById('sceneEditorMain');
      var width = canvas.offsetWidth;
      var height = Math.round(width / 1.777777);
      canvas.style.width = width + 'px';
      canvas.style.height = height + 'px';
      sceneEditor.setSize(width, height);
      console.log('Set canvas size for scene. WxH: ', width, height)
    },
    createSceneMenu() {
      var vm = this;
      var menu = new BootstrapMenu('#sceneEditorMain', {
        actions: [
          {
            name: 'Auto arrange',
            classNames: 'dropdown-item',
            onClick: function() {
              vm.actionMenuAutoArrange();
            }
          },
          {
            name: 'Paste background image',
            classNames: 'dropdown-item',
            onClick: function() {
              vm.actionMenuPaseBgImage();
            }
          },
        ]
      });
      console.log(menu);
    },
    createVideoMenu(id) {
      var vm = this
      var menu = new BootstrapMenu('#' + id, {
        actions: [
          {
            name: 'Fill scene',
            classNames: 'dropdown-item',
            onClick: function() {
              vm.actionMenuItemFill(id);
            }
          },
          {
            name: 'Center screen',
            classNames: 'dropdown-item',
            onClick: function() {
              vm.actionMenuItemCenter(id);
            }
          },
          {
            name: 'Send to back',
            classNames: 'dropdown-item',
            onClick: function() {
              vm.actionMenuItemSendToBack(id);
            }
          },
          {
            name: 'Bring to front',
            classNames: 'dropdown-item',
            onClick: function() {
              vm.actionMenuItemBringToFont(id);
            }
          }
        ]
      });
      console.log("Add context menu to video #", id, menu);
    },
    loadingBgImage() {
      var main = document.getElementById('sceneEditorMain');
      main.style.backgroundImage = 'url(' + this.videoMixer.bgImage.src + ')';
    },
    createObjects() {
      this.clearAllObjects();

      if(this.videoMixer) {
        //main.style.backgroundImage = 'url(' + this.videoMixer.bgImage.src + ')';
        this.loadingBgImage();

        var main = document.getElementById('sceneEditorMain');
        for (const { userId, videoElement } of this.videoMixer.getAll().values()) {
          var element = sceneEditor.getElementById(userId);
          var id = 'sceneUser_' + userId;
          var el = videoElement.cloneNode(true);
          el.srcObject = videoElement.srcObject.clone();
          el.removeAttribute("id");
          el.setAttribute('id', id);
          el.setAttribute('data-id', userId);
          el.classList.add('resize-drag');
          el.style.boxSizing="border-box";
          el.style.backgroundColor="transparent";
          el.style.border="dotted 2px darkgray";
          el.style.position = 'absolute';
          if(!element) {            
            el.style.width = 320 + 'px';
            el.style.height = 180 + 'px';
          }
          main.appendChild(el);

          //Position/size from scene editor
          if(element) {
            this.setSizeVideo(id, element.x, element.y, element.width, element.height);
          }

          console.log('Append new camera from UserId to scene: ', userId);
          this.createVideoMenu(id);
        }
        
        this.interactObject();
      }
    },
    clearAllObjects() {
      var main = document.getElementById('sceneEditorMain');
      while (main.lastElementChild) {
        main.removeChild(main.lastElementChild);
      } 
      console.log('All objects removed from scene.');
    },
    interactObject() { 
      interact('.resize-drag')
      .resizable({
        // resize from all edges and corners
        edges: { left: true, right: true, bottom: true, top: true },

        listeners: {
          move (event) {
            var target = event.target
            var x = (parseFloat(target.getAttribute('data-x')) || 0)
            var y = (parseFloat(target.getAttribute('data-y')) || 0)

            // update the element's style
            target.style.width = event.rect.width + 'px'
            target.style.height = event.rect.height + 'px'

            // translate when resizing from top or left edges
            x += event.deltaRect.left
            y += event.deltaRect.top

            target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
          }
        },
        modifiers: [
          // keep the edges inside the parent
          interact.modifiers.restrictEdges({
            outer: 'parent'
          }),

          // minimum size
          interact.modifiers.restrictSize({
            min: { width: 100, height: 50 }
          })
        ],

        inertia: true
      })
      .draggable({
        listeners: { move: window.dragMoveListener },
        inertia: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ]
      })
    },
    setSizeVideo(id, x, y, width, height) {
      var el = document.getElementById(id);
      if(el) {
        el.style.width = width + 'px';
        el.style.height = height + 'px';
        el.style.transform = 'translate(' + x + 'px,' + y + 'px)';
        el.setAttribute('data-x', x);
        el.setAttribute('data-y', y);
      }
    },
    setPositionVideo(id, x, y) {
      var el = document.getElementById(id);
      el.style.transform = 'translate(' + x + 'px,' + y + 'px)';
      el.setAttribute('data-x', x);
      el.setAttribute('data-y', y);
    },

    //Global scene
    actionMenuAutoArrange() {
      console.log("Auto arrange...");
      const container = document.getElementById('sceneEditorMain');
      const videos = Array.from(container.querySelectorAll('video'));

      let containerWidth = container.clientWidth;
      let padding = 10;
      
      let x = 0, y = 0, maxRowHeight = 0;

      videos.forEach(video => {
        let width = video.offsetWidth;
        let height = video.offsetHeight;

        if (x + width > containerWidth) {
          x = padding;
          y += maxRowHeight + padding;
          maxRowHeight = 0;
        }

        // Встановлюємо позицію
        this.setPositionVideo(video.getAttribute('id'), x, y);

        // Оновлюємо макс. висоту поточного ряду
        maxRowHeight = Math.max(maxRowHeight, height);

        // Зсуваємо x вправо
        x += width + padding;
      });
    },
    actionMenuPaseBgImage() {
      this.videoMixer.pasteImageFromClipboard().then((success) => {
        if (success) 
          this.loadingBgImage();
      });
    },

    //Video scene
    actionMenuItemSendToBack(id) {
      const video = document.getElementById(id);
      if (video)
          video.parentElement.prepend(video);
      console.log("Send to back video #: " + id + ".");
    },
    actionMenuItemBringToFont(id) {
      const video = document.getElementById(id);
      if (video)
          video.parentElement.appendChild(video);
      console.log("Bring to font video #: " + id + ".");
    },
    actionMenuItemCenter(id) {
      var canvas = document.getElementById('sceneEditorMain');

      var el = document.getElementById(id);
      var w = el.offsetWidth;
      var h = el.offsetHeight;

      this.setSizeVideo(id, Math.round((canvas.offsetWidth - w) / 2), Math.round((canvas.offsetHeight - h) / 2), w, h);
    },
    actionMenuItemFill(id) {
      var canvas = document.getElementById('sceneEditorMain');
      var width = canvas.offsetWidth;
      var height = Math.round(width / 1.777777);

      this.setSizeVideo(id, 0, 0, width, height);

      console.log("Fill video #: " + id + " to scene. WxH: " + width + 'x' + height + 'px.');
    },

    //Save/Read
    saveScene() {
      sceneEditor.clear();

      const container = document.getElementById('sceneEditorMain');
      const videos = Array.from(container.querySelectorAll('video'));
      var i = 0;
      videos.forEach(video => {
        var id = video.getAttribute('data-id');
        var x = video.getAttribute('data-x');
        var y = video.getAttribute('data-y');
        var w = video.offsetWidth;
        var h = video.offsetHeight;
        if(id) {
          sceneEditor.addElement({
            userId: id,
            type: "camera",
            x: x,
            y: y,
            z: i,
            width: w,
            height: h,
            radius: 0,
          });
        }
        i++;
      });

      console.log("Saved.");
    }
  },
  computed: {
  }
};
</script>

<template>
  <b-modal @shown="modalShown" @hidden="modalHidden" hide-footer :hide-header-close="false" title="Scene editor" class="zoomIn sceneEditorModal" size="xl">
    <div id="sceneEditorMain" style="position:relative">
    </div>
    <div class="modal-footer v-modal-footer pt-3 pb-0 pe-0">
      <button type="button" @click="saveScene" class="btn btn-primary">Save</button>
      <a href="javascript:void(0);" ref="closeButton" class="btn btn-light fw-medium" data-bs-dismiss="modal">Close</a>
    </div>
  </b-modal>
</template>
