export const sceneEditor = {
    elements: [],
    converted: [],
    width: 0,
    height: 0,
    videoMixer: null,

    setVideoMixer(videoMixer) {
        this.videoMixer = videoMixer;
    },

    setSize(width, height) {
        this.width = width;
        this.height = height;
    },

    clear() {
        this.elements = [];
        this.converted = [];
    },

    addElement(newElement) {
        this.elements.push(newElement);
        this.convertToOriginal(newElement.userId);
        this.videoMixer.updateTracks(this.converted);
    },

    getElementById(userId) {
        return this.elements.find(el => el.userId === userId) || null;
    },

    getConvertedById(userId) {
        return this.converted.find(el => el.userId === userId) || null;
    },

    convertToOriginal(userId) {
        var element = this.getElementById(userId);
        if(!userId) 
            return null;

        var clone = {
            userId: userId,
            type: "camera",
            x: this.calcConvert(element.x, this.width, 1280),
            y: this.calcConvert(element.y, this.height, 720),
            width: this.calcConvert(element.width, this.width, 1280),
            height: this.calcConvert(element.height, this.height, 720),
            z: element.z,
            radius: 0,
        };
        this.converted.push(clone);
    },

    calcConvert(value, current, original) {
        return Math.round(value * original / current);
    },

    existsById(userId) {
        return this.elements.some(el => el.userId === userId);
    }
};