import { sceneEditor } from "./scene-editor";

export default class VideoMixer {
    constructor(useOffscreenCanvas = false) {
        this.canvas = useOffscreenCanvas && typeof OffscreenCanvas !== 'undefined'
            ? new OffscreenCanvas(1280, 720)
            : document.createElement('canvas');
        this.tracks = new Map();
        this.changeSizeCanvas();
        this.loadingImages();
        this.intervalId = null;
        this.currentSpeakers = null;
        this.backgroundCanvas = null;
        this.frameInterval = 1000 / 30;
        sceneEditor.setVideoMixer(this);
    }

    getAll() {
        return this.tracks;
    }

    addTrack(id, userId, track) {
        if (this.tracks.has(id)) {
            console.log(`Track with ID ${id} already exists. Use replaceTrack instead.`);
        } else { 
            const videoElement = document.getElementById('videoPlayer_' + id);
            console.log(track);
            this.tracks.set(id, { connectionId: id, userId, videoElement });
            this.startRendering();
        }
    }

    removeTrack(id) {
        if (!this.tracks.has(id)) {
            console.log(`No track with ID ${id} to remove.`);
        } else {
            this.tracks.delete(id);
            if (this.tracks.size === 0) {
                this.stopRendering();
            }
        }
    }

    updateTracks(elements) {
        for (let track of this.tracks.values()) {
            let element = elements.find(el => el.userId === track.userId);
            if (element) {
                Object.assign(track, element);
            }
        }
        this.sortTracksByZ();
    }

    sortTracksByZ() {
        const sortedTracks = Array.from(this.tracks.values()).sort((a, b) => (a.z || 0) - (b.z || 0));
        this.tracks = new Map(sortedTracks.map(track => [track.connectionId, track]));
    }

    removeTracksExcept(ids) {
        const missingIds = Array.from(this.tracks.keys()).filter(id => !ids.includes(id));
        missingIds.forEach((id) => {
            this.removeTrack(id);
        });
    }

    changeSizeCanvas() {
        var length = this.tracks ? this.tracks.size : 0;
        console.log('Change size canvas: ', length);
        this.canvas.width = 1280;
        this.canvas.height = 720;
        console.log('Canvas size (W x H): ', this.canvas.width + 'x' + this.canvas.height);
        this.context = this.canvas.getContext('2d', { willReadFrequently: true });
        this.outputStream = this.canvas.captureStream(30);
    }

    loadingImages() {
        //Watermark logo
        this.isLogoImageReady = false;
        this.logoImage = new Image();
        this.logoImage.src = '/logo-light-small.png';
        this.logoImage.onload = () => {
            console.log('Logo image is ready to draw.');
            this.isLogoImageReady = true;
        };

        //Background
        this.isBgImageReady = false;
        this.bgImage = new Image();
        this.bgImage.src = '/stream-bg.jpg';
        this.bgImage.onload = () => {
            console.log('BG image is ready to draw.');
            this.isBgImageReady = true;
        };
    }

    pasteImageFromClipboard() {
        return new Promise((resolve, reject) => {
            try {
                navigator.clipboard.read().then((clipboardItems) => {
                    let imageFound = false;
    
                    for (const item of clipboardItems) {
                        for (const type of item.types) {
                            if (type.startsWith('image/')) {
                                item.getType(type).then((blob) => {
                                    const img = new Image();
                                    img.src = URL.createObjectURL(blob);
                                    
                                    img.onload = () => {
                                        console.log('BG image is ready to draw.');
                                        this.bgImage = img;
                                        resolve(true);
                                    };
                                });
    
                                imageFound = true;
                                break;
                            }
                        }
                        if (imageFound) 
                            break;
                    }
    
                    if (!imageFound) {
                        console.log("Clipboard image not found");
                        resolve(false);
                    }
                }).catch((error) => {
                    console.error("Clipboard read error: ", error);
                    reject(error);
                });
            } catch (error) {
                console.error("Clipboard paste error: ", error);
                reject(error);
            }
        });
    }

    getMixedVideoTrack() {
        console.log('Video render video tracks: ', this.outputStream.getVideoTracks());
        return this.outputStream.getVideoTracks()[0];
    }

    startRendering() {
        if (this.intervalId) 
            return;

        //Active speaker            
        this.context.strokeStyle = "#6691E7";
        this.context.lineWidth = 5;
            
        console.log('Go render!');
        this.lastFrameTime = performance.now();
        const render = () => {
            if (!this.context || this.tracks.size === 0) 
                return;

            const cellWidth = this.canvas.width / this.tracks.size;
            const cellHeight = this.canvas.height / this.tracks.size;
            
            //Clear
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            
            //Background
            this.drawBackground();
                
            let i = 0;
            //for (const { userId, videoElement } of this.tracks.values()) {
            for (let track of this.tracks.values()) {
                //var element = sceneEditor.getConvertedById(userId);
                var x, y, w, h;
                if (track.x !== undefined && track.x !== null) {
                    x = track.x;
                    y = track.y;
                    w = track.width;
                    h = track.height;
                } else {
                    x = i;
                    y = this.tracks.size > 1 
                        ? (this.canvas.height - cellHeight) / 2 
                        : 0;
                    w = cellWidth;
                    h = cellHeight;
                }
                this.context.drawImage(track.videoElement, x, y, w, h);
                if(this.currentSpeakers && this.currentSpeakers.includes(track.userId)) {
                    this.context.strokeRect(x, y, w, h - 1);
                }
                i += cellWidth;
            }

            if (this.isLogoImageReady) {
                this.context.drawImage(this.logoImage, this.canvas.width - 151, this.canvas.height - 36, 151, 36);
            }
        };

        this.intervalId = setInterval(() => { 
            const now = performance.now();
            if (now - this.lastFrameTime >= this.frameInterval) {
                this.lastFrameTime = now;
                render();
                this.frameCount++;
            }
        }, 0); //30 FPS (this.frameInterval)

        //Log FPS
        // setInterval(() => {
        //     console.log(`FPS: ${this.frameCount}`);
        //     this.frameCount = 0;
        // }, 1000);
    }

    drawBackground() {
        if (this.isBgImageReady && this.bgImage.complete) {
            this.context.drawImage(this.bgImage, 0, 0, this.canvas.width, this.canvas.height);
        }
    }

    stopRendering() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
        if (this.context) {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }
    }

    currentSpeaker(list) {
        this.currentSpeakers = list;
    }

    clean() {
        this.tracks = new Map();
    }
}
